.sjs-surveys-list {
    font-size: 16px;
    line-height: 40px;
    width: 100%;
    table-layout: fixed;
}

.sjs-surveys-list__footer {
    margin: 48px auto;
    /* max-width: 500px; */
}

.sjs-button {
    appearance: none;
    -webkit-appearance: none;
    text-decoration: none;
    outline: none;
    border-radius: 100px;
    background: transparent;
    padding: 8px 24px;
    border: none;
    color: var(--primary, #19b394);
    cursor: pointer;
    font-weight: 600;
    text-align: center;
}

.sjs-button:hover {
    /* background-color: var(--primary, #19b394); */
    background-color: var(--primary-light, rgba(25, 179, 148, 0.1));
}

.sjs-add-btn {
    display: inline-block;
    padding: 16px 48px;
    background: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    text-align: center;
    border: 2px solid transparent;
    width: 100%;
    box-sizing: border-box;
}

.sjs-add-btn:hover,
.sjs-add-btn:focus {
    border-color: var(--primary, #19b394);
    background-color: white;
}

.sjs-remove-btn {
    color: var(--danger, #e60a3e);
}

.sjs-remove-btn:hover,
.sjs-remove-btn:focus {
    /* background-color: var(--danger, #e60a3e); */
    background-color: var(--danger-light, rgba(230, 10, 62, 0.1));
}